.display-wrapper {
    height: var(--display-height);
    min-height: 80vh;
    position: relative;
}

.map-image-wrapper {
    height: var(--display-height);
    width: calc(100vw - 65px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.popover-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 12px;
    border-radius: 5px;

    .react-colorful {
        width: 300px;
        height: 150px;
    }

    .react-colorful__saturation-pointer {
        width: 16px;
        height: 16px;
        border-radius: 3px;
    }

    .react-colorful__hue-pointer {
        width: 15px;
        height: 15px;
        border-radius: 3px;
    }

    .react-colorful__hue {
        margin-top: 5px;
        height: 15px;
        border-radius: 3px;
    }
}
