.tarkov-time-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px;

    .weatherimg {
        width: 25px;
        height: 25px;
    }
    
}