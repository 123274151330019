@import "../../colors.scss";

.credits {
    color: $text-color;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 50px;
    font-size: 17px;
    text-align: justify;
    .attribution-header {
        color: $tarkov-brown;
        margin-top: 30px;
        margin-bottom: 10px;
    }
}