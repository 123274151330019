.trader-node {
    width: 150px;
    height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 12px;
    color: white;
    img {
        height: 100%;
        width: auto;
        overflow: hidden;
        border-radius: 2px;
    }
  }
