.items-fir-container {
    padding-right: 20px;
    padding-left: 15px;
    margin-top: 15px;
}

.items-fir-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.items-fir-cards {
    // Will auto fill cards when 300px is available

    // display: grid;
    // gap: 15px;
    // grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));


    // We set the column size based on the screen size

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    @media only screen and (max-width: 1800px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media only screen and (max-width: 1450px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 750px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
    }

}