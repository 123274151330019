@import "./colors.scss";

@font-face {
    font-family: "Bender";
    src: url("../public/fonts/Bender.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Bender";
    src: url("../public/fonts/Bender-Italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Bender";
    src: url("../public/fonts/Bender-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Bender";
    src: url("../public/fonts/Bender-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "Bender";
    src: url("../public/fonts/Bender-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Bender";
    src: url("../public/fonts/Bender-BoldItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Bender";
    src: url("../public/fonts/Bender-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Bender";
    src: url("../public/fonts/Bender-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}

:root {
    --display-height: auto;
}

.page-container{
    margin-left: 65px;    
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    // background: #f1f1f1;
    background: #cecece;
    // background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    // background: #888;
    background: rgb(99, 99, 99);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    // background: #555;
    background: rgb(73, 73, 73);
}
