$tarkov-black: #0d0d0d;
$tarkov-lightbrown: #8a7c64;
$tarkov-ebony: #212529;
$tarkov-white: #ebebeb;

$tarkov-brown: #9a8866;
$tarkov-brown-highlight: #b6a990;
$text-color: #aeaeb0;
$back-color: #0e0e0e;
$nav-color: #242424; //1f1f21

$prapor: #31210f;
$prapor-highlight: #96622d;
$prapor-highlight-completed: #96622d;

$therapist: #380a1b;
$therapist-highlight: #871742;
$therapist-highlight-completed: #db2c6f;

$skier: #362803;
$skier-highlight: #825f07;
$skier-highlight-completed: #d99e0b;

$peacekeeper: #0a1933;
$peacekeeper-highlight: #193d7a;
$peacekeeper-highlight-completed: #2965cc;

$mechanic: #340e05;
$mechanic-highlight:#7d220b ;
$mechanic-highlight-completed: #d13913;

$ragman: #002d29;
$ragman-highlight: #006c63;
$ragman-highlight-completed: #00b3a4;

$jaeger: #0a290d;
$jaeger-highlight: #19641f;
$jaeger-highlight-completed: #29a634;

$fence: #363636;
$fence-highlight: #5f5f5f;
$fence-highlight-completed: #8a8a8a;

$lightkeeper: #391846;
$lightkeeper-highlight: #8641a1;
$lightkeeper-highlight-completed: #b06bcc;

$ref: #013842;
$ref-highlight: #0088a0;
$ref-highlight-completed: #00bddf;


$btr-driver: #382b3d;
$btr-driver-highlight: #7a5e86;
$btr-driver-highlight-completed: #9577a1;
